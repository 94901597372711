import React from 'react';
import T from 'prop-types';

/**
 * Prevents widows on texts by replacing the space between the last
 * two words with a non-breaking space.
 *
 * Allows you to turn this:
 *  You have to see it to believe
 *  it
 *
 * into this:
 *  You have to see it to
 *  believe it
 */
export function widont(string, breakBefore = 18) {
  var s = string.split(' ');

  if (s.length > 1) {
    var l = s.length;
    var breakLength = (s[l - 1] + ' ' + s[l - 2]).length;

    if (breakBefore === 'always' || breakLength < breakBefore) {
      return (
        <>
          {s.slice(0, l - 1).join(' ')}&nbsp;{s[l - 1]}
        </>
      );
    }
  }

  return string;
}

export const Widont = ({ children, breakBefore }) =>
  widont(children, breakBefore);

Widont.propTypes = {
  children: T.string,
  breakBefore: T.number
};
