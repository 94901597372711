import React from 'react';
import T from 'prop-types';
import { Link } from 'gatsby';
import { Button } from '@devseed-ui/button';

import Layout from '../../components/layout';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageBody,
  InpageLead
} from '../../styles/inpage';
import {
  Section,
  SectionHeader,
  SectionHeadline,
  SectionTitle,
  SectionSuptitle,
  SectionLead,
  SectionBody
} from '../../styles/section';
import { Widont } from '../../components/widont';
import { MosaicFeatures } from '../../components/mosaic';
import Media from '../../components/media';
import { CallToAction, CTAActions } from '../../components/call-to-action';

import coverImg from '../../media/layout/how-we-work--cover.jpg';

const valuesItems = [
  {
    title: 'Focus on Impact',
    children: (
      <React.Fragment>
        <p>
          We prioritize projects that confront pressing climate and social
          concerns, driving our genuine commitment, a trait that consistently
          brings our partners back for more collaboration.
        </p>
      </React.Fragment>
    )
  },
  {
    title: 'Built for Change',
    children: (
      <React.Fragment>
        <p>
          The world is changing, and so is your program&apos;s context.
          We&apos;ve honed our solution frameworks and built a philosophy to
          accommodate change. We start each project with an evidence-based plan,
          then adapt together to accommodate evolving knowledge and
          opportunities.
        </p>
      </React.Fragment>
    )
  },
  {
    title: 'Deliver Value',
    children: (
      <React.Fragment>
        <p>
          Leveraging open tooling and internal frameworks, we launch products
          swiftly, often within weeks. We incorporate continuous learning, thus
          preserving time and resources for our most informed state of
          development to deliver value rapidly.
        </p>
      </React.Fragment>
    )
  },
  {
    title: 'About You',
    children: (
      <React.Fragment>
        <p>
          You are seeking a thought partner and co-creator of a solution to
          enhance your organization&apos;s efforts to positively impact society
          and the planet, aiming to accelerate or amplify this impact through
          improved technology and data that addresses your program needs.
        </p>
      </React.Fragment>
    )
  }
];

const HowWeWork = ({ location }) => {
  return (
    <Layout location={location} title='How we work' metaImage={coverImg}>
      <Inpage>
        <InpageHeader>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>
                <Widont>How We Work</Widont>
              </InpageTitle>
            </InpageHeadline>
            <InpageLead>
              <p>
                Discover the Development Seed difference, where innovative
                software development meets a profound commitment to impactful
                geospatial solutions. Our approach centers on strong
                partnerships and a clear, agile process, transforming challenges
                into opportunities for genuine change.
              </p>
            </InpageLead>
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody isSoftPadded>
          <Media
            size='cover'
            decoration='bottom'
            src={coverImg}
            alt='People meeting in a room'
          />
          <Section>
            <SectionHeader>
              <SectionHeadline>
                <SectionTitle>
                  <Widont>How We Work</Widont>
                </SectionTitle>
                <SectionSuptitle>Work With Us</SectionSuptitle>
              </SectionHeadline>
              <SectionLead>
                <p>
                  At Development Seed, our approach to software development is
                  deeply rooted in a product mindset that prioritizes impactful,
                  meaningful solutions.
                </p>
                <p>
                  We focus first on people and partnerships, understanding that
                  great products stem from strong, aligned relationships and a
                  shared commitment to making a positive difference.
                </p>
                <p>
                  Our agile, no-fluff process is designed to cut through
                  distractions, ensuring that every project we undertake is not
                  just about meeting goals, but about creating geospatial tools
                  that drive genuine change and resonate with users.
                </p>
              </SectionLead>
            </SectionHeader>
            <SectionBody>
              <MosaicFeatures items={valuesItems} />
            </SectionBody>
          </Section>

          <CallToAction
            title='Have a challenging project that could use our help?'
            suptitle="Let's connect"
          >
            <p>We&apos;d love to hear from you.</p>
            <CTAActions>
              <Button
                variation='primary-fill'
                size='large'
                forwardedAs={Link}
                to='/contact'
              >
                Contact us
              </Button>
            </CTAActions>
          </CallToAction>
        </InpageBody>
      </Inpage>
    </Layout>
  );
};

export default HowWeWork;

HowWeWork.propTypes = {
  location: T.object
};
